<template>
  <div class="list-info">
    <top-bar title="巡查列表" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="text" placeholder="请输入申请人查询" class="search-content">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="12">
          <p @click="codeShow=!codeShow" :style="{color:codeShow?'#387FF5':'#666666'}">
            {{codeStr}}
            <img :src="require(`@/assets/img/${codeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="12">
          <p @click="statusShow=!statusShow" :style="{color:statusShow?'#387FF5':'#666666'}">
            {{statusStr}}
            <img :src="require(`@/assets/img/${statusShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="codeShow" position="bottom">
            <van-picker title="请选择" show-toolbar :columns="codeList" value-key="name" @confirm="codeConfirm" @cancel="codeShow = false"  />
    </van-popup>
    <van-popup v-model="statusShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm" @cancel="statusCancel"  />
    </van-popup>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id">
          <div class="title">
            <div class="title-left">
              {{item.codeStr}}{{item.failureTime ? ' | ' + item.failureTime : ''}}
            </div>
            <div class="title-right">
              {{item.auditStatusStr}}
            </div>
          </div>
          <div class="content2">
            <div  class="content-img">
              <img :src="getImg('excel')">
              <p class="content-downLoad" @click="downLoad(item.url)">下载文件</p>
            </div>
            <div class="content-text">
              <div class="top-text">{{item.exportUserStr}} <span>/{{item.dataRows}}条</span></div>
              <div class="center-text">密码： {{item.excelPwd || '无密码'}}</div>
              <div class="center-text">申请原因： {{item.des || '无'}}</div>
            </div>
            <div class="content-btns" v-if="item.auditStatusStr === '未审'">
              <div class="content-btn">
                <van-button plain size="small" type="info" @click="changeStatus(item.id, 1)">同意</van-button>
              </div>
              <div class="content-btn">
                <van-button plain size="small" type="danger" @click="changeStatus(item.id, 0)">拒绝</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {getDictTree} from '@/utils/common'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      codeStr: '所有',
      statusStr: '审核状态',
      status: '',
      loading: false,
      finished: false,
      codeShow: false,
      statusShow: false,
      page: 0,
      limit: 10,
      searchValue: '',
      codeId: '',
      codeList: [],
      statusList:  [{ id: 0, label: '未审' }, { id: 1, label: '审核通过' }, { id: 2, label: '拒绝通过' }, { id: 3, label: '已失效' }],
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getImg (type) {
      return  getImageStream('files/wx/images/content/'+type+'.png')
    },
    downLoad (url) {
      const link = document.createElement('a');
      link.href = getImageStream(url);
      link.download = '导出审核.xlsx'; //下载
      link.click();//进行点击时下载
    },
    //同意导出申请
    changeStatus (id, status) {
      this.$dialog.confirm({
        title: status === 1 ? '同意' : '拒绝',
        message: `确定要${status === 1 ? '同意' : '拒绝'}吗?`,
      })
          .then(() => {
            this.$http({
              url: this.$http.adornUrl('/wxapp/reportexportlog/ratify'),
              method: 'post',
              params: this.$http.adornParams({
                agree: status,
                id,
              })
            }).then(({data})=> {
              if (data.code == 0) {
                this.page = 0
                this.dataList = []
                this.getDataList()
                this.$toast.success(`操作成功`)
              } else {
                this.$toast.fail(data.msg);
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/reportexportlog/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          userName: this.searchValue,
          codeStr: this.codeStr === '所有' ? '' : this.codeStr,
          status: this.status
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getCodeList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/reportexportlog/getType'),
        method: 'get'
      }).then(({data})=> {
        if (data.code == 0) {
          this.codeList = data.list
          this.codeList.unshift({name: '所有', codeId: ''})
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    codeConfirm (value,index) {
      console.log(value)
      this.page = 0
      this.dataList = []
      this.codeStr = value.name
      this.codeId = value.code
      this.getDataList()
      this.codeShow = false
    },
    statusConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.statusStr = value.label
      this.status = value.id
      this.getDataList()
      this.statusShow = false
    },
    statusCancel () {
      this.page = 0
      this.dataList = []
      this.statusStr = '文档类型'
      this.status = ''
      this.getDataList()
      this.statusShow = false
    },
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    // this.statusId = this.$globalData.statusInfo.statusId
    this.getDataList()
    this.getCodeList()
  }
}
</script>
